import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IDboxResourcesComponentsModule } from 'idbox-web-resources';
import { CardGenericListComponent } from './card-generic-list/card-generic-list.component';
import { JoinRoutesPipe } from '../core/utils/route.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { CanShowLoadMoreTreeNavigationPipe } from '../core/utils/can-show-load-more-tree-navigation.pipe';
import { SearchableListModalPage } from './searchable-list-modal/searchable-list-modal.page';
import { SearchBarFilterPipe } from '../core/utils/search-bar-filter.pipe';
import { EnabledApplicationsPipe } from '../core/utils/enabled-applications.pipe';

@NgModule({
  declarations: [HeaderComponent, CardGenericListComponent,
    JoinRoutesPipe, CanShowLoadMoreTreeNavigationPipe, SearchableListModalPage, SearchBarFilterPipe,
    EnabledApplicationsPipe
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    IDboxResourcesComponentsModule,
    TranslateModule
  ],
  exports: [HeaderComponent, CardGenericListComponent,
    CommonModule, IonicModule, FormsModule, ReactiveFormsModule, IDboxResourcesComponentsModule, TranslateModule,
    JoinRoutesPipe, CanShowLoadMoreTreeNavigationPipe, SearchBarFilterPipe,SearchableListModalPage,
    EnabledApplicationsPipe]
})
export class CommonComponentsModule { }
