import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DataTypeSearchableListModal } from 'src/app/core/models/SearchableListModal.model';

@Component({
  selector: 'app-searchable-list-modal',
  templateUrl: './searchable-list-modal.page.html',
  styleUrls: ['./searchable-list-modal.page.scss'],
})
export class SearchableListModalPage implements OnInit {

  @Input() dataSelectable: DataTypeSearchableListModal[];
  @Input() titleModal: string;
  option: string;
  searchKeyword: string;
  
  constructor(
    public modalCtrl: ModalController
  ) { }

  ngOnInit() {
  }

  submitSelectionAndCloseModal(){
    this.modalCtrl.dismiss(this.option);
  }

}
