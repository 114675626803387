import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { ErrorMessagesHandler, TranslatedAPIMessage, TranslationService } from 'idbox-web-resources';

export enum TypeMessageToast  {
  ERROR = "error",
  SUCESS = "success",
}

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor(
    private toastCtrl: ToastController,
    private translator: TranslationService,
    private errorHandler: ErrorMessagesHandler
  ) { }

  handleIDboxAPIError(error: HttpErrorResponse, keyMessage: string, typeMessage: TypeMessageToast) {
    if (!this.getErrorMessage(error, typeMessage)) {
      this.presentToastMessage(this.translator.translateText(keyMessage), typeMessage)
    }
  }

  private getErrorMessage(error: HttpErrorResponse, typeMessage: TypeMessageToast): boolean {
    let descriptionError: string = '';
    let resourcesKey: string = '';

    if (error && error.status) {
      const translatedAPIMessage: TranslatedAPIMessage = this.errorHandler.getAPIErrorMessage(error, descriptionError, resourcesKey);
      descriptionError = translatedAPIMessage.descriptionError;
      resourcesKey = translatedAPIMessage.resourcesKey;
      // If resourcesKey == descriptionError it means that no humanized text for the error has been found - either they are empty or the key does not exist
      // Otherwise there is an error for the user to display
      if (descriptionError != resourcesKey) {
        this.presentToastMessage(descriptionError, typeMessage);
      }
    }
    return descriptionError != resourcesKey;
  }

  async presentToastMessage(text: string, typeMessage: TypeMessageToast) {
    const toastClass = typeMessage === "error" ? "toastClass-error" : "toastClass-success";
    const toast: HTMLIonToastElement = await this.toastCtrl.create({
      message: text,
      position: "bottom",
      duration: 4000,
      cssClass: toastClass
    });
    toast.present();
  }

}
