import { HttpInterceptor, HttpSentEvent, HttpHeaderResponse, HttpHandler, HttpEvent, HttpRequest, HttpHeaders, HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, from } from "rxjs";
import { Injectable } from "@angular/core";
import { ConfigEnvironment, TranslationService } from "idbox-web-resources";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MobileAuthService } from "./services/mobile-auth.service";
import { EndpointManagerService } from "idbox-web-dataservice-lib";

//////////////////////////////////////////////////////////////////////////////////////////////////////////
//Esta clase intercepta las llamadas HTTP de la aplicación móvil para incluir el Token de seguridad en la cabecera de la petición
//////////////////////////////////////////////////////////////////////////////////////////////////////////
@Injectable()
export class HttpMobileTokenInterceptor implements HttpInterceptor {

  public static shouldCatch401: boolean = true;

  public static hasAlreadyOpenedAConfirmDialog: boolean = false;

  constructor(private http: HttpClient, private mobileAuthService: MobileAuthService,
    private translator: TranslationService, private dialog: MatDialog,
    private endpointManagerService: EndpointManagerService,
    private config: ConfigEnvironment) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // convert promise to observable using 'from' operator
    return from(this.handle(req, next))
  }

  async handle(req: HttpRequest<any>, next: HttpHandler) {
    let headers = {};
    //let isOpenPermalinkCall: boolean = false;

    if (this.shouldPutIDboxHeaders(req)) {
      let hasAuthHeader: boolean;
      try {
        hasAuthHeader = req.headers != null && Object.keys(req.headers).length > 0 && req.headers.has("Authorization")
      }
      catch (err) {//workaround for not logged permalinks call
        hasAuthHeader = false;
      }

      if (hasAuthHeader) {
        headers["Authorization"] = req.headers.get("Authorization");
      }
      else {
        // let permalinkHeader = this.authService.getOpenPermalinkHeader();
        // if (permalinkHeader) {
        //   headers["Open-Permalink"] = permalinkHeader;
        //   isOpenPermalinkCall = true;
        // }
        // else {
        let authHeader = this.mobileAuthService.getAuthorizationHeaderValue();
        if (authHeader) {
          headers["Authorization"] = authHeader;
        }
        //}
      }

      // if (!(req.body instanceof FormData)) {//TODO: If temporal, ya que el 'Content-Type' application/json no debería de estar aquí.
      //   headers["Content-Type"] = 'application/json';
      // }

      let secGroup: number = await this.mobileAuthService.getSecurityGroupStorage();
      if (secGroup != null) {
        headers["Security-Group"] = secGroup.toString();//toString is needed, or calls will fail
      }

      if (this.shouldPutApplicationJsonContentTypeHeader(req)) {
        headers["Content-Type"] = 'application/json';
      }

      let jsonReq: HttpRequest<any> = req.clone({
        setHeaders: headers
      });

      return next.handle(jsonReq).toPromise();//when update rxjs, we can use lastValueFrom instead of toPromise which is deprecated on superior versions
    }//if is not a call to IDbox API, we do not add the headers (e.g. login or refresh tokens calls on mobile fails if have that extra headers)
    else {
      return next.handle(req).toPromise();//do not apply any change
    }

  }

  shouldPutIDboxHeaders(req: HttpRequest<any>): boolean {
    return this.config != null && this.config.baseUrl != null && //if we do not have the configEnvironment loaded, we have not logged in, so we don't have to put the headers (we know that if we have this.config.baseUrl != null, we have loaded the config environment)
        (req.url.startsWith(this.config.baseUrl + this.config.apiUrl)//IDbox api
      || req.url.startsWith(this.config.assetsAPI.assetUrl)//tessa
      || req.url.startsWith(this.config.assetsAPI.excelUrl)//tessa-excel
      || req.url.startsWith(this.config.assetsAPI.lookupUrl)//tessa-lookup
      || req.url.startsWith(this.config.reportsAPIUrl));//reports-api
  }

  shouldPutApplicationJsonContentTypeHeader(req: HttpRequest<any>) {
    return req.url.startsWith(this.config.baseUrl + this.config.apiUrl + this.endpointManagerService.HISTORICURL);
  }

}
