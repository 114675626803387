import { Component, NgZone, OnInit } from '@angular/core';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { ConfigEnvironment, TranslationService } from 'idbox-web-resources';
import { MobileAuthService } from './core/services/mobile-auth.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MobileSettingsKeys, MobileSettingsService } from './core/services/mobile-settings.service';
import { Browser } from '@capacitor/browser';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { LoginStorageService } from './core/services/login-storage.service';
import { RoutesEnum } from './core/common/Routes';
import { LoginDiscoveryConfiguration, MobileConfigurationAPIService } from './core/services/mobile-configuration-api.service';
import { take, takeUntil } from 'rxjs/operators';
import { BaseSuscription } from './core/common/BaseSuscription';
import { LoadingsService } from './core/utils/loadings.service';
import { AppThemeService } from './core/utils/app-theme.service';
import { Capacitor } from '@capacitor/core';
import { ErrorHandlerService, TypeMessageToast } from './core/utils/error-handler.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent extends BaseSuscription implements OnInit {

  readonly initialUrl: string = "https://development.idboxrt.com";
  url: string = this.initialUrl;

  hasAlreadySetURLListener: boolean = false;

  constructor(
    private loginStorageService: LoginStorageService,
    private oauthService: OAuthService,
    private zone: NgZone,
    private activatedRoute: ActivatedRoute,
    private mobileAuthService: MobileAuthService,
    private mobileSettingsService: MobileSettingsService,
    private loadingServ: LoadingsService,
    private translationService: TranslationService,
    private router: Router,
    private mobileConfigurationAPIService: MobileConfigurationAPIService,
    private appThemeService: AppThemeService,
    private errorHandlerService: ErrorHandlerService
  ) {
    super();
    this.translationService.setRegisteredLocales();
  }

  ngOnInit() {
    this.isLoading = true;

    this.appThemeService.applyThemeApp();

    const initLoginPromise = new Promise(async (resolve, reject) => {
      await this.loadingServ.presentLoading(this.translationService.translateText('HOME.LOADING'))

      await this.mobileAuthService.removeSelectedSecurityGroup();

      await this.loginStorageService.loadDataOnAllKeys();
      let storedUrl: string = await this.mobileSettingsService.get(MobileSettingsKeys.Url);
      console.log("App - OnInit storedUrl = " + storedUrl);
      if (storedUrl != null) {
        this.url = storedUrl;

        this.mobileConfigurationAPIService.getLoginDiscoveryConfiguration(this.url).pipe(take(1), takeUntil(this.ngUnsubscribe)).subscribe((loginDiscoveryConfiguration: LoginDiscoveryConfiguration) => {
          this.configureOAuth(loginDiscoveryConfiguration);
          this.validateLogin();//only validate login here since if no url is found on session storage, no user have been authenticated  
        }, async error => {
          this.errorHandlerService.presentToastMessage(`Login Discovery error<br>${error.message}`, TypeMessageToast.ERROR); //TODO: ESTO HAY QUE CAMBIARLO Obviamente por una clave de traducción "Login Discovery error"
          await this.removeLoginLoading();
        });
      }
      else {
        await this.removeLoginLoading();
      }
    });

    initLoginPromise.then(() => {
      //do nothing. All is done in the promise
    }).catch((error: Error) => {
      console.error('IDbox OAuth - initLoginPromise errorMsg = ' + error.message + ' errorStack=' + error.stack);
    });
  }

  //#region Login

  private async removeLoginLoading() {
    await this.loadingServ.dismissLoading();
    this.isLoading = false;
  }

  private configureOAuth(loginDiscoveryConfiguration: LoginDiscoveryConfiguration): void {
    if (Capacitor.isNativePlatform()) {
      this.configureOAuthMobile(loginDiscoveryConfiguration);
    } else {
      this.configurOAuthDesktop(loginDiscoveryConfiguration);
    }
  }

  /**
   * Metodo para validar si se ha iniciado sesion
   */
  validateLogin() {
    this.oauthService.loadDiscoveryDocument()
      .then(loadDiscoveryDocumentResult => {
        //Do we have a valid access token? -> User does not need to log in
        this.mobileAuthService.hasValidAccessToken = this.oauthService.hasValidAccessToken();

        //Always call tryLogin after the app and discovery document loaded, because we could come back from Keycloak login page.
        //The library needs this as a trigger to parse the query parameters we got from Keycloak.
        this.oauthService.tryLogin().then(async tryLoginResult => {
          if (this.mobileAuthService.hasValidAccessToken) {
            this.loadConfigEnv();
          } else {
            await this.cleanAndRedirectToLogin();
          }
        });

      })
      .catch(async error => {
        console.error('IDbox OAuth ValidateLogin errorMsg = ' + error.message + ' errorStack=' + error.stack);
        await this.mobileAuthService.cleanLogin();
        await this.removeLoginLoading();
      });


    this.oauthService.events.subscribe(eventResult => {
      this.mobileAuthService.hasValidAccessToken = this.oauthService.hasValidAccessToken();
    })
  }

  private async cleanAndRedirectToLogin() {
    await this.mobileAuthService.cleanLogin();
    await this.removeLoginLoading();
    this.router.navigateByUrl("/");
  }

  loadConfigEnv() {
    this.mobileConfigurationAPIService.getMobileConfiguration(this.url).pipe(take(1), takeUntil(this.ngUnsubscribe)).subscribe(async (configEnvironment: ConfigEnvironment) => {
      //when making this call, the service stores in MobileConfigurationAPIService.configEnvironment the value of the configEnvironment
      this.mobileAuthService.loadUserProfile(this.callbackSuccess.bind(this), this.callbackError.bind(this));
    }, error => {
      console.error('IDbox OAuth loadConfigEnv errorMsg = ' + error.message + ' errorStack=' + error.stack);
      this.cleanAndRedirectToLogin();
    });
  }

  async callbackSuccess() {
    await this.removeLoginLoading();
    this.router.navigateByUrl(RoutesEnum.Explorer);
  }

  async callbackError(error) {
    await this.loginError(error);
  }

  /**
   * Metodo del evento click para cargar la configuracion del login
   */
  async onClickLogin(url: string) {

    await this.loadingServ.presentLoading(this.translationService.translateText('HOME.LOADING'))
    this.url = url;
    this.mobileConfigurationAPIService.getLoginDiscoveryConfiguration(url).pipe(take(1), takeUntil(this.ngUnsubscribe)).subscribe((loginDiscoveryConfiguration: LoginDiscoveryConfiguration) => {
      this.configureOAuth(loginDiscoveryConfiguration);
      console.log("IDbox OAuth - Prueba log...");
      console.log("IDbox OAuth - Loading discovery document and login...");
      this.oauthService.loadDiscoveryDocumentAndLogin()
        .then(async loadDiscoveryDocumentAndLoginResult => {
          await this.mobileSettingsService.set(MobileSettingsKeys.Url, this.url);
          await this.removeLoginLoading();
          console.log("loadDiscoveryDocumentAndLoginResult = " + loadDiscoveryDocumentAndLoginResult);
        })
        .catch(async error => {
          await this.loginError(error);
        });
    }, async error => {
      this.errorHandlerService.presentToastMessage(`Login Discovery error<br>${error.message}`, TypeMessageToast.ERROR); //TODO: ESTO HAY QUE CAMBIARLO Obviamente por una clave de traducción "Login Discovery error"
      await this.removeLoginLoading();
    });

  }

  async loginError(error: Error) {
    console.error(error);
    this.errorHandlerService.presentToastMessage(`Login Error<br>${error.message}`, TypeMessageToast.ERROR); //TODO: ESTO HAY QUE CAMBIARLO Obviamente por una clave de traducción "Login Discovery error"
    await this.mobileAuthService.cleanLogin();
    await this.removeLoginLoading();
  }

  async onLogout() {
    this.mobileSettingsService.remove(MobileSettingsKeys.Url);

    this.oauthService.logoutUrl = null;//Workaround for not redirecting to logout URL, since method revokeTokenAndLogout(), does not let pass the param of not redirecting to the logoutURL that has the logout() method, called inside revokeTokenAndLogout(), and when discovering the discovery document the logout url is set, but if the logout method sees that it does not have logoutURL, it does not redirect to it, as we want
    this.oauthService.revokeTokenAndLogout().then(async revokeTokenAndLogoutResult => {
      console.log("IDbox OAuth - revokeTokenAndLogout", revokeTokenAndLogoutResult);
      this.url = this.initialUrl;
      await this.mobileAuthService.cleanLogin();
    })
      .catch(error => {
        console.error('IDbox OAuth - revokeTokenAndLogout errorMsg = ' + error.message + ' errorStack=' + error.stack);
      });
  }

  /**
   * Configures the app for web deployment
   * @private
   */
  private configurOAuthDesktop(loginDiscoveryConfiguration: LoginDiscoveryConfiguration): void {
    try {
      console.log("IDbox OAuth - Using Web configuration");
      let authConfig: AuthConfig = this.convertFromLoginDiscoveryConfigurationToAuthConfig(loginDiscoveryConfiguration);
      authConfig.strictDiscoveryDocumentValidation = false;
      authConfig.skipIssuerCheck = true;
      authConfig.showDebugInformation = true;
      this.configureOAuthCommon(authConfig);
    } catch (error) {
      console.error('IDbox OAuth - configureWeb errorMsg = ' + error.message + ' errorStack=' + error.stack);
    }
  }

  private configureOAuthCommon(authConfig: AuthConfig) {
    this.oauthService.configure(authConfig);
    this.oauthService.setupAutomaticSilentRefresh();
  }

  /**
     * Configures the app for Android or iOS deployment
     * @private
     */
  private configureOAuthMobile(loginDiscoveryConfiguration: LoginDiscoveryConfiguration): void {
    try {
      console.log("IDbox OAuth - Using Mobile configuration");
      let authConfig: AuthConfig = this.convertFromLoginDiscoveryConfigurationToAuthConfig(loginDiscoveryConfiguration);

      authConfig.strictDiscoveryDocumentValidation = false;
      authConfig.skipIssuerCheck = true;
      authConfig.showDebugInformation = true;
      authConfig.requireHttps = false;
      authConfig.openUri = (uri) => {
        Browser.open({ url: uri });
      }

      authConfig.logoutUrl = null;//There is no logout URL, for the library to not redirect to aer(authConfig);

      this.configureRedirectListener(authConfig);

    } catch (error) {
      console.error('IDbox OAuth - configureOAuthMobile errorMsg = ' + error.message + ' errorStack=' + error.stack);
    }
  }
  convertFromLoginDiscoveryConfigurationToAuthConfig(loginDiscoveryConfiguration: LoginDiscoveryConfiguration): AuthConfig {
    let authConfig: AuthConfig = new AuthConfig();
    authConfig.issuer = loginDiscoveryConfiguration.issuer;
    authConfig.redirectUri = loginDiscoveryConfiguration.redirectUri;
    authConfig.clientId = loginDiscoveryConfiguration.clientId;
    authConfig.responseType = loginDiscoveryConfiguration.responseType;
    authConfig.scope = loginDiscoveryConfiguration.scope;
    return authConfig;
  }

  private configureRedirectListener(authConfig: AuthConfig) {
    this.configureOAuthCommon(authConfig);

    if (!this.hasAlreadySetURLListener) {
      console.log("IDbox OAuth - Configuring appUrlOpen listener...");
      this.hasAlreadySetURLListener = true;
      App.addListener('appUrlOpen', async (event: URLOpenListenerEvent) => {

        try {
          await this.loadingServ.presentLoading(this.translationService.translateText('HOME.LOADING'))
          console.log('IDbox OAuth - App opened with URL:', event.url);


          let url = new URL(event.url);
          // console.log('IDbox OAuth - Check URL Login Host = url.host = '+url.host);
          // console.log('IDbox OAuth - Check URL Login Host = url.hostname = '+url.hostname);
          // console.log('IDbox OAuth - Check URL Login Host = url.protocol = '+url.protocol);
          // if (url.host != "login") { //with out scheme this does not work
          // if we need different 
          //   console.log('IDbox OAuth - App No Login URL');
          //   // Only interested in redirects to idboxrt://login
          //   return;
          // }
          console.log('IDbox OAuth - Entering Zone');
          this.zone.run(() => {
            console.log('IDbox OAuth - App Entered Zone');
            // Building a query param object for Angular Router
            const queryParams: Params = {};
            url.searchParams.forEach((value, key) => {
              queryParams[key] = value;
              console.log('IDbox OAuth - Param key= ' + key + ' value=' + value);
            });

            console.log('IDbox OAuth - Before router.navigate');
            // Add query params to current route
            this.router.navigate(
              [],
              {
                relativeTo: this.activatedRoute,
                queryParams: queryParams,
                queryParamsHandling: 'merge', // remove to replace all query params by provided
              })
              .then(navigateResult => {
                console.log('IDbox OAuth - After navigate result =' + navigateResult);
                // After updating the route, trigger login in oauthlib and
                this.oauthService.tryLogin().then(async tryLoginResult => {
                  console.log("IDbox OAuth - tryLoginResult =", tryLoginResult);
                  this.mobileAuthService.hasValidAccessToken = this.oauthService.hasValidAccessToken();
                  console.log("IDbox OAuth - hasValidAccessToken=", this.mobileAuthService.hasValidAccessToken);
                  if (this.mobileAuthService.hasValidAccessToken) {
                    this.loadConfigEnv();
                  }
                  else {
                    await this.cleanAndRedirectToLogin();
                  }
                });
              })
              .catch(async error => {
                await this.removeLoginLoading();
                console.error('IDbox OAuth - tryLogin appUrlOpen errorMsg = ' + error.message + ' errorStack=' + error.stack);
              }
              );

          });

        } catch (error) {
          console.error('IDbox OAuth - appUrlOpen - configureOAuth errorMsg = ' + error.message + ' errorStack=' + error.stack);
        }
      });
    }

    else {
      console.log("IDbox OAuth - Listener appUrlOpen has already been configured...");
    }
  }

  //#endregion

}