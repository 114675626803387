import { AssetResponse, HierarchyElement, HierarchyElementType, HierarchyType, OrganizerResponse, Document, DocumentType, GraphicDocument, Folder } from "idbox-data-model";
import {HierarchyElementTreeNode} from 'idbox-web-hierarchy-tree-lib';
import { DocumentHelper } from "idbox-web-resources";

export enum HierarchyState {
  Opened = "Opened",
  Closed = "Closed",
  Expanding = "Expanding",
  LoadingMore = "LoadingMore",
}

export class NavigationExplorerElement<T> {
  
  public element: T;
  public title: string;
  public state: HierarchyState;
  public numberOfElements: number;
  public children: NavigationExplorerElement<T>[];
  public hierarchyType: HierarchyType;
  public icon : string;
  public parent : NavigationExplorerElement<T>;
  public numPage : number;

  public static isSameNavigationElement(navigationElement1: NavigationExplorerElement<OrganizerResponse | AssetResponse | HierarchyElement>, navigationElement2: NavigationExplorerElement<OrganizerResponse | AssetResponse | HierarchyElement>): boolean {
    return navigationElement1 != null && navigationElement2 != null 
    && navigationElement1.element != null && navigationElement2.element != null
    && navigationElement1.element.id == navigationElement2.element.id 
    && navigationElement1.hierarchyType == navigationElement2.hierarchyType;
  }
  
  public static convertFromElementToTreeElementBase<T>(element: T, parentNode : NavigationExplorerElement<T>): NavigationExplorerElement<T> {
    let navigationExplorerElement = new NavigationExplorerElement<T>();
    navigationExplorerElement.element = element;
    navigationExplorerElement.parent = parentNode;
    navigationExplorerElement.state = HierarchyState.Closed;//all items starts closed
    return navigationExplorerElement;
  }

  public static convertFromOrganizerToTreeElement(organizer: OrganizerResponse, parentNode : NavigationExplorerElement<OrganizerResponse| AssetResponse>): NavigationExplorerElement<OrganizerResponse| AssetResponse> {
    let navigationExplorerElement = NavigationExplorerElement.convertFromElementToTreeElementBase<OrganizerResponse | AssetResponse>(organizer, parentNode);
    navigationExplorerElement.hierarchyType = HierarchyType.AssetOrganizator;
    navigationExplorerElement.title = organizer.name;
    navigationExplorerElement.icon = HierarchyElementTreeNode.getIconInventory(organizer, navigationExplorerElement.hierarchyType);
    navigationExplorerElement.numberOfElements = organizer.numOrganizers + organizer.numElements;//numElements are the number of assets inside the organizer
    return navigationExplorerElement;
  }

  public static convertFromAssetToTreeElement(asset: AssetResponse, parentNode : NavigationExplorerElement<OrganizerResponse | AssetResponse>): NavigationExplorerElement<OrganizerResponse | AssetResponse> {
    let navigationExplorerElement = NavigationExplorerElement.convertFromElementToTreeElementBase<OrganizerResponse | AssetResponse>(asset, parentNode);
    navigationExplorerElement.hierarchyType = HierarchyType.Asset;
    navigationExplorerElement.title = asset.name;
    navigationExplorerElement.icon = HierarchyElementTreeNode.getIconInventory(asset, navigationExplorerElement.hierarchyType);
    navigationExplorerElement.numberOfElements = asset.numComplexAssets;
    return navigationExplorerElement;
  }

  static convertFromHierarchyElementToTreeElement(hierarchyElement: HierarchyElement, parentNode: NavigationExplorerElement<HierarchyElement>): NavigationExplorerElement<HierarchyElement> {
    let navigationExplorerElement = NavigationExplorerElement.convertFromElementToTreeElementBase<HierarchyElement>(hierarchyElement, parentNode);
    navigationExplorerElement.hierarchyType = HierarchyType.IDboxHierarchy;
    navigationExplorerElement.title = hierarchyElement.name;
    let icon;
    let numberOfElements = 0;
    switch (hierarchyElement.hierarchyElementType) {
      case HierarchyElementType.Folder:
        icon = 'ti-folder';
        numberOfElements = (hierarchyElement as Folder).numberOfHierarchyElements;
        break;
      case HierarchyElementType.SymbolicLink:
        icon = 'ti-link';
        break;
      case HierarchyElementType.Document:
        const document = hierarchyElement as Document;
        icon = NavigationExplorerElement.GetDocumentIcon(document);
        break;
    }
    navigationExplorerElement.icon = icon;
    navigationExplorerElement.numberOfElements = numberOfElements;
    return navigationExplorerElement;
  }

  public static GetDocumentIcon(el: Document) {
    let subType: any = null;
    if (el.documentType == DocumentType.Chart) {
      subType = (el as GraphicDocument).graphicType;
    }
    let res = DocumentHelper.GetIconOfDocumentType(el.documentType as DocumentType, subType);
    return res;
  }

  public static addOrganizersToList(organizers: OrganizerResponse[], list: NavigationExplorerElement<OrganizerResponse | AssetResponse>[], parentNode : NavigationExplorerElement<OrganizerResponse | AssetResponse>) {
    if (organizers != null && organizers.length > 0) {
      organizers.forEach(organizer => {
        list.push(NavigationExplorerElement.convertFromOrganizerToTreeElement(organizer, parentNode));
      });
    }
  }

  public static addAssetsToList(assets: AssetResponse[], list: NavigationExplorerElement<OrganizerResponse | AssetResponse>[], parentNode : NavigationExplorerElement<OrganizerResponse | AssetResponse>) {
    if (assets != null && assets.length > 0) {
      assets.forEach(asset => {
        list.push(NavigationExplorerElement.convertFromAssetToTreeElement(asset, parentNode));
      })
    }
  }

  static addHierarchyElementsToList(hierarchyElements: HierarchyElement[], list: NavigationExplorerElement<HierarchyElement>[], parentNode : NavigationExplorerElement<HierarchyElement>) {
    if (hierarchyElements != null && hierarchyElements.length > 0) {
      hierarchyElements.forEach(hierarchyElement => {
        list.push(NavigationExplorerElement.convertFromHierarchyElementToTreeElement(hierarchyElement, parentNode));
      });
    }
  }

  public static getSkeletonNavigationExplorerElement<T>(numberOfElements:number){
    let skeletonExplorer: NavigationExplorerElement<T>[] = [];
    for (let i = 0; i < numberOfElements; i++) {
      let skeletonElement = new NavigationExplorerElement<T>();
      skeletonExplorer.push(skeletonElement);
    }
    return skeletonExplorer;

  }

  public static getParentElementFromNode<T>(navigationElement: NavigationExplorerElement<T>, rootNodeNavigationElement: NavigationExplorerElement<T>) : NavigationExplorerElement<T> {
    let parentElement = null;
    if (!navigationElement.parent) { //if it is a node that does not have parent, it means that its parent is the root node
      parentElement = rootNodeNavigationElement;

    } else {
      parentElement = navigationElement.parent;
    }
    return parentElement;
  }
}

export class ExplorerBreadCrumb<T>{
  breadcrumbElement : NavigationExplorerElement<T>;
}