import { Pipe, PipeTransform } from '@angular/core';
import { HierarchyState, NavigationExplorerElement } from 'src/app/core/models/ExplorerModel';

@Pipe({
  name: 'canShowLoadMoreTreeNavigation',
  pure: false//cannot be pure, since the element can change its children without changing the input
})
export class CanShowLoadMoreTreeNavigationPipe implements PipeTransform {

  transform<T>(navigationElement: NavigationExplorerElement<T>): boolean {
    if (!navigationElement) {
      return false;
    }
    let numberOfElements = 0;
    let totalLoaded = 0;

    numberOfElements = navigationElement.numberOfElements || 0;
    totalLoaded = navigationElement.children ? navigationElement.children.length : 0;

    // Determine if the "Load More" button should be displayed
    const hasMoreToLoad = numberOfElements > totalLoaded;

    // Return the combination of conditions to show the button
    return hasMoreToLoad && !(navigationElement.state == HierarchyState.LoadingMore);
  }
}
