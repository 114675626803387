import { Pipe, PipeTransform } from "@angular/core";
import { Application } from "../models/ApplicationsModel";

@Pipe({
  name: 'enabledApplications',
  pure: false,
})
export class EnabledApplicationsPipe implements PipeTransform {
  constructor() { }

  transform(applications: Application[]) {
    let enabledApplications = [];
    if (applications != null) {
      enabledApplications = applications.filter(app => app.enabled);
    }
    return enabledApplications;
  }

}