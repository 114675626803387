import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { NavigationExplorerElement } from 'src/app/core/models/ExplorerModel';

@Component({
  selector: 'card-generic-list',
  templateUrl: './card-generic-list.component.html',
  styleUrls: ['./card-generic-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardGenericListComponent<T> implements OnInit {
  static readonly SKELETON_NUMBER_OF_OBJECTS: number = 4;

  private _genericList: NavigationExplorerElement<T>[];
  @Input() get genericList() {
    return this._genericList;
  }

  set genericList(value: NavigationExplorerElement<T>[]) {
    this._genericList = value;
    if (!this.isLoading) {
      this.list = this.genericList;//we update the list, if the input has changed, but we did not show any loading (for example the list changes from having elements to not having, but no API call was made)
    }
  }

  @Input() cardTemplate: TemplateRef<any>;

  @Input() showButtonLoadMore: boolean;

  //Anyone using this component must set isLoading to false once the data has been passed.
  private _isLoading: boolean = true;
  @Input()
  get isLoading() {
    return this._isLoading;
  }
  set isLoading(value: boolean) {
    if (value != null) {
      this._isLoading = value;
      if (this._isLoading) {
        this.list = this.skeletonList;
      }
      else {
        this.list = this.genericList;
      }
    }
  }

  // To control the ion-skeleton when "Load more" is used
  private _loadingMoreData: boolean;
  @Input()
  get loadingMoreData() {
    return this._loadingMoreData;
  }
  set loadingMoreData(value: boolean) {
    if (value != null) {
      this.loadingMoreDataItem = value
    }
  }

  @Output() onElementClicked = new EventEmitter<NavigationExplorerElement<T>>();
  @Output() loadMoreDataEvent = new EventEmitter<void>(); // Event to load more data

  list: NavigationExplorerElement<T>[];
  skeletonList: NavigationExplorerElement<T>[];

  loadingMoreDataItem: boolean = false;

  constructor(public cdr: ChangeDetectorRef) {
    this.skeletonList = NavigationExplorerElement.getSkeletonNavigationExplorerElement(CardGenericListComponent.SKELETON_NUMBER_OF_OBJECTS);
  }

  ngOnInit() {
  }

  elementClicked(explorerElement: NavigationExplorerElement<T>) {
    this.onElementClicked.next(explorerElement);
  }

  loadMoreData() {
    this.loadMoreDataEvent.emit();
  }

}
