import { NgModule } from '@angular/core';
import { PagesRoutingModule } from './pages-routing.module';
import { CommonComponentsModule } from '../components/components.module';
import { AboutComponent } from './about/about.component';
import { AdminSecurityDataService } from 'idbox-web-dataservice-lib';
import { BaseModule } from '../base.module';


@NgModule({
  declarations: [AboutComponent],
  imports: [
    BaseModule.forRoot(),
    CommonComponentsModule,
    PagesRoutingModule,
  ],
  providers: [
    AdminSecurityDataService
  ]
})
export class PagesModule { }
