export enum ThemeSelectionOptions {
    LIGHT = "Light",
    DARK = "Dark",
    INHERIT_FROM_DEVICE = "Tema del dispositivo" // TODO: Traducción pendiente
}

export enum AppTheme {
    LIGHT = "light",
    DARK = "dark"
}
