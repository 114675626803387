import { OnDestroy } from "@angular/core";
import { Subject } from "rxjs";

export class BaseSuscription implements OnDestroy {

    protected isLoading: boolean = true;
    protected ngUnsubscribe: Subject<any> = new Subject();

    constructor(){
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}