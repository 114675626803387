import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingsService {

  private loading: HTMLIonLoadingElement = null;
  constructor(private loadingCtrl: LoadingController) { }

  public async presentLoading(message: string = ""): Promise<void> {
    this.loading = await this.loadingCtrl.create({
      spinner: "crescent",
      message: message,
      backdropDismiss: false,
      cssClass: 'custom-loading-spinner'
    });
    await this.loading.present();
  }

  public async dismissLoading(): Promise<void> {
    if (this.loading) {
      await this.loading.dismiss();
      this.loading = null;  // Reset to avoid reuse issues
    }
  }
}
