import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { AlertsService } from '../core/utils/alerts.service';
import { ConfigEnvironment, TranslationService } from 'idbox-web-resources';
import { AuthenticationService } from 'idbox-web-core-security-lib';
import { RoutesEnum } from '../core/common/Routes';
import { MobileAuthService } from '../core/services/mobile-auth.service';
import { AppThemeService } from '../core/utils/app-theme.service';
import { Browser } from '@capacitor/browser';
import { Application } from '../core/models/ApplicationsModel';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent implements OnInit {

  @Output() OnLogoutClick: EventEmitter<void> = new EventEmitter<void>();

  applicationMenuActive: boolean = false;

  RoutesEnum = RoutesEnum;
  public appPages = [
    { route: RoutesEnum.Explorer, iconSrc: 'id-explorer.svg', iconName: null, label: 'TABS.EXPLORER_TITLE' },
    { route: RoutesEnum.Documents, iconSrc: null, iconName: 'documents-outline', label: 'HIERARCHYTREE.DOCUMENTS' },
    // { route: '', iconSrc: 'id-starempty.svg', iconName: null, label: 'TABS.FAVORITES' }, //Falta implementar: RoutesEnum.Favorites
    { route: RoutesEnum.About, iconSrc: null, iconName: 'information-circle-outline', label: 'TABS.ABOUT_TITLE' },
    { route: RoutesEnum.Settings, iconSrc: null, iconName: 'settings-outline', label: 'USER.SETTINGS' },
  ];

  constructor(
    public authService: AuthenticationService,
    private translationService: TranslationService,
    private alertSer: AlertsService,
    public menuCtrl: MenuController,
    public mobileAuthService: MobileAuthService,
    private appThemeService: AppThemeService,
    public configEnvironment: ConfigEnvironment,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.appThemeService.applyTextColorBasedOnCorporate();
  }

  /**
   * Method to close the main menu
   */
  closeMenu() {
    this.menuCtrl.close();
  }

  /**
   * Handle logout
   */
  async handleLogout() {
    const fntAcept = () => {
      this.OnLogoutClick.next();
    }
    this.alertSer.showConfirmationAlert(
      this.translationService.translateText('HOME.CONFIRMATION'),
      this.translationService.translateText('SESSIONS.REMOVE_SESSION'),//'Do you want to log out?'
      this.translationService.translateText('BUTTONS.ACCEPT'),
      fntAcept.bind(this)
    );
  }

  //#region Applications
  changeApplicationMenuActive($event: boolean) {
    this.applicationMenuActive = $event;
  }

  //#endregion

}
