import { Injectable } from '@angular/core';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { MobileSettingsService } from './mobile-settings.service';
import { KeysResult } from '@capacitor/preferences';


@Injectable({
    providedIn: 'root'
})
export class LoginStorageService implements OAuthStorage {

    private loginData: Record<string, string> = {};

    constructor(private mobileSettingsService: MobileSettingsService) {
    }

    async loadDataOnAllKeys() {//we load the data on all keys, to have it available synchronously
        let keysResult: KeysResult = await this.mobileSettingsService.loadAllKeys();//A side effect is that we cannot distinguish only the keys of the login, but the time of getting the rest of the keys is negligible
        if (keysResult && keysResult.keys != null && keysResult.keys.length > 0) {
            for (let i = 0; i < keysResult.keys.length; i++) {
                const key: string = keysResult.keys[i];
                const result: string = await this.mobileSettingsService.get(key);
                this.loginData[key] = result;
            }
        }
    }

    getItem(key: string): string {
        return this.loginData[key];
    }
    removeItem(key: string): void {
        this.loginData[key] = null;
        this.mobileSettingsService.remove(key);//is a Promise, but we cannot wait, since the OAuthStorage, is not async, so we store it also in the dictionary
    }
    setItem(key: string, data: string): void {
        this.loginData[key] = data;
        this.mobileSettingsService.set(key, data);
    }
}