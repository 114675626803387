import { LOCALE_ID, NgModule } from "@angular/core";
import { HttpMobileTokenInterceptor } from "./core/http-mobile-token-interceptor";
import { OAuthStorage } from "angular-oauth2-oidc";
import { LoginStorageService } from "./core/services/login-storage.service";
import { ModuleWithProviders } from "@angular/core";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { ConfigEnvironment } from "idbox-web-resources";
import { MobileConfigurationAPIService } from "./core/services/mobile-configuration-api.service";

@NgModule({

})
export class BaseModule {

  static forRoot(): ModuleWithProviders<BaseModule> {
    return {
      ngModule: BaseModule,
      providers: [
        { provide: LOCALE_ID, useValue: 'es' },
        { provide: OAuthStorage, useExisting: LoginStorageService },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpMobileTokenInterceptor,//even though this HttpMobileTokenInterceptor is passed in AuthenticationModule.forRoot(HttpMobileTokenInterceptor), it needs to be here as well, since otherwise we will not have the interceptor on the lazyLoaded modules.
          multi: true
        },
        { provide: ConfigEnvironment, useValue: MobileConfigurationAPIService.configEnvironment },//when starting the app this variable, might be empty, and is updated by reference, since it is the way to have it dynamically updated
      ]
    };
  }
}


