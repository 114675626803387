import { Injectable } from '@angular/core';
import { MobileSettingsKeys, MobileSettingsService } from '../services/mobile-settings.service';
import { AppTheme, ThemeSelectionOptions } from '../models/ThemeSettings';
import { AuthenticationService } from 'idbox-web-core-security-lib';

@Injectable({
  providedIn: 'root'
})
export class AppThemeService {

  constructor(
    private mobileSettingsService: MobileSettingsService,
    private authService: AuthenticationService
  ) { }

  async applyThemeApp(): Promise<void> {
    const currentStoredTheme: string = await this.mobileSettingsService.get(MobileSettingsKeys.themeStorageKey);
    const themeToApply: string = !currentStoredTheme ? ThemeSelectionOptions.INHERIT_FROM_DEVICE : currentStoredTheme
    this.updateThemePreference(themeToApply)
  }

  // Method to update the theme preference
  async updateThemePreference(newThemeStorage: string): Promise<boolean> {
    let isSuccess = true;

    try {
      let finalTheme: string;

      switch (newThemeStorage) {
        case ThemeSelectionOptions.LIGHT:
          finalTheme = AppTheme.LIGHT;
          break;

        case ThemeSelectionOptions.DARK:
          finalTheme = AppTheme.DARK;
          break;

        case ThemeSelectionOptions.INHERIT_FROM_DEVICE:
          finalTheme = window.matchMedia('(prefers-color-scheme: dark)').matches
            ? AppTheme.DARK
            : AppTheme.LIGHT;
          break;

        default:
          finalTheme = AppTheme.LIGHT; // Fallback
          break;
      }

      // Apply the theme
      this.applyTheme(finalTheme);

      // Save the value in Capacitor preferences
      await this.mobileSettingsService.set(MobileSettingsKeys.themeStorageKey, newThemeStorage);

    } catch (error) {
      console.error('Error updating the theme preference:', error);
      isSuccess = false;
    }

    return isSuccess;
  }


  // Apply the theme
  private applyTheme(theme: string): void {
    const body: HTMLBodyElement = document.body as HTMLBodyElement;
    if (theme === AppTheme.DARK) {
      body.classList.add('dark-theme');
    } else {
      body.classList.remove('dark-theme');
    }
  }



  // Function to apply the text color based on the corporate color
  applyTextColorBasedOnCorporate(): void {
    const corporateColor: string = this.authService.userIDbox.defaultSecurityGroup.color;

    if (corporateColor) {
      const textColor: string = this.isColorLight(corporateColor) ? 'var(--ion-color-dark)' : 'var(--ion-color-light)';
      document.documentElement.style.setProperty('--text-label-menu-color', textColor);
    }
  }

  // Function to convert HEX color to RGB
  private hexToRgb(hex: string): { r: number, g: number, b: number } | null {
    const shorthandRegex: RegExp = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
    const result: RegExpExecArray = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  // Function to calculate relative luminance
  private relativeLuminance(r: number, g: number, b: number): number {
    const a: number[] = [r, g, b].map(function (v) {
      v /= 255;
      return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
  }

  // Function to determine if the background color is light or dark
  private isColorLight(hexColor: string): boolean {
    const rgb: { r: number, g: number, b: number } | null = this.hexToRgb(hexColor);
    if (!rgb) return true; // If the color is invalid, assume it is light.

    const luminance: number = this.relativeLuminance(rgb.r, rgb.g, rgb.b);

    // Luminance > 0.5 is considered light, < 0.5 is considered dark
    return luminance > 0.5;
  }

}
