import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslationService } from 'idbox-web-resources';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  constructor(
    private alertController: AlertController,
    private translationService:TranslationService
  ) { }
  
  /**
   * Metodo para generar una alerta
   * 
   * @param title Titulo de la alerta
   * @param message Mensaje a mostrar
   */
  async showAlert(title: string, message: string){
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: title,
      message,
      buttons: [this.translationService.translateText('BUTTONS.ACCEPT')],
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
  }

  /**
   * 
   * @param title Titulo de la alerta
   * @param message Mensaje a mostrar
   * @param textFntAcept Texto del boton aceptar
   * @param fntAcept Funcion a ejecutarse en el boton aceptar
   * @param titleFntCancel Texto del boton cancelar
   * @param fntCancel Funcion a ejecutarse en el boton cancelar
   */
  async showConfirmationAlert(title: string, message: string, textFntAcept: string, fntAcept: Function, titleFntCancel: string = this.translationService.translateText('BUTTONS.CANCEL'), fntCancel: Function = () => {}) {
    const alert = await this.alertController.create({
      header: title,
      message,
      buttons: [
        {
          text: titleFntCancel,
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            fntCancel();
          },
        },
        {
          text: textFntAcept,
          handler: () => {
            fntAcept();
          },
        },
      ],
    });

    await alert.present();
  }
}
