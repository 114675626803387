import { Injectable } from '@angular/core';
import { KeysResult, Preferences } from '@capacitor/preferences';

export enum MobileSettingsKeys {
  Url = "Url", // Main URL of the server we are targeting
  SecurityGroupSwitchStorageKey = "SecurityGroupSwitchStorageKey", // Identifier of the security group we want to switch to
  SecurityGroupStorageKey = "SecurityGroupStorageKey", // Identifier of the current security group (the one we are in)
  themeStorageKey = "themeStorage" // Identifier of the current theme of the app
}

/**
 * Service for storing string settings on the mobile app.
 */

@Injectable({
  providedIn: 'root'
})
export class MobileSettingsService {

  constructor() { }

  async get(key: string): Promise<string> {
    return (await Preferences.get({ key: key })).value;
  }

  async set(key: string, value: string) {
    await Preferences.set({ key: key, value: value });
  }

  async remove(key: string) {
    await Preferences.remove({ key: key });
  }

  async loadAllKeys(): Promise<KeysResult> {
    return await Preferences.keys();
  }
}
