import { Pipe, PipeTransform } from "@angular/core";
import { RoutesEnum } from "../common/Routes";

@Pipe({ name: 'joinRoutes',
    pure: true,
 })
export class JoinRoutesPipe implements PipeTransform {
  constructor() { }

  transform(routes: RoutesEnum[]): string {
        return JoinRoutesPipe.joinRoutes(routes);
  }

  static joinRoutes(routes: RoutesEnum[]): string {
    return routes.join('/');
  }
}