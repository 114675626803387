import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router'
import { AboutComponent } from './about/about.component';
import { RoutesEnum } from '../core/common/Routes';
import { SettingsPage } from './settings/settings.page';

const routes: Routes = [
  {
    path: RoutesEnum.Explorer,
    loadChildren: () => import('./explorer/explorer.module').then(m => m.ExplorerModule)
  },
  {
    path: RoutesEnum.Users,
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
  },
  {
    path: RoutesEnum.Documents,
    loadChildren: () => import('./documents/documents.module').then(m => m.DocumentsModule),
  },
  { path: RoutesEnum.About, component: AboutComponent },
  {
    path: RoutesEnum.Settings,
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsPageModule),
  },
  {
    path: RoutesEnum.Applications+"/:id",
    loadChildren: () => import('./applications/applications.module').then(m => m.ApplicationsModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
