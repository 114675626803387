import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { SearchableListModalPage } from './components/searchable-list-modal/searchable-list-modal.page';
import { RoutesEnum } from './core/common/Routes';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: RoutesEnum.SearchableListModal,
    component: SearchableListModalPage
  },

  /*{
    path: "",
    component: AppComponent
  }*/

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
