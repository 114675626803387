import { Document, DocumentType } from 'idbox-data-model';

export enum RoutesEnum {
    About = 'about',
    PostLogin = 'postlogin',
    Explorer = 'explorer',
    Users = 'users',
    Profile = 'profile',
    Documents = 'documents',
    Synoptic = 'synoptic',
    Charts = 'charts',
    Reports = 'reports',
    Settings = 'settings',
    Applications = 'applications',
    SearchableListModal = 'searchable-list-modal',
}

export class Routes {
    public static getDocumentRoute(document: Document) {
        let documentRoute;
        switch (document.documentType) {
            case DocumentType.Synoptic:
                documentRoute = RoutesEnum.Synoptic;
                break;
            case DocumentType.Chart:
                documentRoute = RoutesEnum.Charts;
                break;
            case DocumentType.Report:
                documentRoute = RoutesEnum.Reports;
                break;
        }
        return documentRoute;
    }
}
