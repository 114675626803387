import { Component, Input, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { HeaderOption } from 'src/app/core/models/HeaderOptions.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() title: string = "";
  @Input() optionsRight: HeaderOption[] = [];
  @Input() optionLeft: HeaderOption;
  @Input() isLoading: boolean = false;

  constructor(private menuCtrl: MenuController) {
    this.menuCtrl.enable(true);
  }

  ngOnInit() {
  }

  onRightIconClick(option: HeaderOption) {
    option.clickEvent();
  }

  onLeftIconClick() {
    this.optionLeft.clickEvent();
  }

}
