import { Injectable } from "@angular/core";
import { Capacitor } from "@capacitor/core";

export enum PlatformEnum {
  android = 'android',
  ios = 'ios',
  web = 'web'
}

@Injectable({
  providedIn: 'root'
})
export class PlatformHelperService {

  getPlatformValue(): PlatformEnum {//Just if something changes on the future, we have out own values, (e.g. for sending it to the API)
    let platform: PlatformEnum;
    switch (Capacitor.getPlatform()) {
      case "android":
        platform = PlatformEnum.android;
        break;
      case "ios":
        platform = PlatformEnum.ios;
        break;
      case "web":
        platform = PlatformEnum.web;
        break;
    }
    return platform;
  }
}