import { Injectable } from '@angular/core';
import { OAuthService, UserInfo } from 'angular-oauth2-oidc';
import { AccessGroup, SecurityGroup, User, UserPreferences } from 'idbox-data-model';
import { AuthenticationService, Configuration } from 'idbox-web-core-security-lib';
import { Subject, forkJoin } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { MobileSettingsKeys, MobileSettingsService } from './mobile-settings.service';
import { CacheService, ConfigEnvironment, PreferencesService } from 'idbox-web-resources';
import { BaseSuscription } from '../common/BaseSuscription';
import { MobileConfigurationAPIService } from './mobile-configuration-api.service';

@Injectable({
  providedIn: 'root'
})
export class MobileAuthService extends BaseSuscription {
  public userProfile: UserInfo;
  public hasValidAccessToken = false;
  public realmRoles: string[] = [];

  constructor(private oauthService: OAuthService,
    private authService: AuthenticationService,
    private mobileSettingsService: MobileSettingsService,
    private cacheService: CacheService,
    private preferences: PreferencesService) {
    super();
  }

  async getSecurityGroupStorage(): Promise<number> {
    return +(await this.mobileSettingsService.get(MobileSettingsKeys.SecurityGroupStorageKey));
  }
  async setSecurityGroupStorage(id: number) {
    await this.mobileSettingsService.set(MobileSettingsKeys.SecurityGroupStorageKey, id.toString());
  }

  async removeSecurityGroupStorage() {
    await this.mobileSettingsService.remove(MobileSettingsKeys.SecurityGroupStorageKey);
  }

  getAuthorizationHeaderValue(): string {
    let authorizationHeader: string = null;
    if (this.userProfile) {
      authorizationHeader = 'Bearer ' + this.oauthService.getAccessToken();
    }
    return authorizationHeader;
  }

  async changeSelectedSecurityGroup(securityGroup: SecurityGroup) {
    if (securityGroup != null && securityGroup.id != null) {
      await this.setSecurityGroupStorage(securityGroup.id);
      this.authService.selectedSecurityGroup = securityGroup;
    }
  }

  async removeSelectedSecurityGroup() {
    await this.removeSecurityGroupStorage();
    this.authService.selectedSecurityGroup = null;
  }

  async getSecurityGroupSwitchStorage(): Promise<number> {
    const securityGroupSwitch: string = await this.mobileSettingsService.get(MobileSettingsKeys.SecurityGroupSwitchStorageKey)
    return securityGroupSwitch ? Number(securityGroupSwitch) : null;
  }

  async setSecurityGroupSwitchStorage(id: number) {
    this.mobileSettingsService.set(MobileSettingsKeys.SecurityGroupSwitchStorageKey, id.toString());
  }

  async removeSecurityGroupSwitchStorage() {
    await this.mobileSettingsService.remove(MobileSettingsKeys.SecurityGroupSwitchStorageKey);
  }

  async cleanLogin() {
    this.userProfile = null;
    this.realmRoles = [];
    this.hasValidAccessToken = false;
    Object.assign(MobileConfigurationAPIService.configEnvironment, {});//we just assign, since otherwise we lose the reference, and the providers will not work, since this value is dynamic depending on the environment
    await this.removeSelectedSecurityGroup();
  }

  /**
   * Calls the library loadUserProfile() method, sets the result in this.userProfile and load the idbox user and security groups
   */
  public loadUserProfile(callbackSuccess: () => void, callbackError: (error) => void): void {
    console.log("IDbox OAuth - loadUserProfile");
    this.oauthService.loadUserProfile()
      .then(async loadUserProfileResult => {
        //console.log("IDbox OAuth - loadUserProfileResult=" + JSON.stringify(loadUserProfileResult));
        this.userProfile = loadUserProfileResult;

        this.loadInitialConfiguration(callbackSuccess, callbackError);
      })
      .catch(async error => {
        console.error('loadUserProfile errorMsg = ' + error.message + ' errorStack=' + error.stack);
        callbackError(error);
      });
  }

  private loadInitialConfiguration(callbackSuccess: () => void, callbackError: (error) => void) {
    let requests = [];

    requests.push(this.authService.getIdentityUser());
    requests.push(this.authService.getConfiguration());
    requests.push(this.authService.loadSecGroupsObservable());
    // if (this.authService.permalinkParameter) {
    //   requests.push(this.shortenerService.getShorten(this.authService.permalinkParameter));
    // }

    forkJoin(requests).pipe(take(1), takeUntil(this.ngUnsubscribe)).subscribe(
      async response => {

        const u: User = response[0] as User;
        const config: Configuration = response[1] as Configuration;
        const secGroups: SecurityGroup[] = response[2] as SecurityGroup[];
        if (secGroups == null) {
          console.error("There are no security groups loaded for that user.");
        }

        this.authService.availableSecurityGroups = secGroups;
        this.authService.configuration = config;
        this.authService.userIDbox = u;

        let secGroupId = null;

        // if (this.authService.permalinkParameter) {
        //   let shortener: Shortener = response[3] as Shortener;
        //   if (shortener) {
        //     this.authService.permalinkLongParameter = shortener.value;

        //     let jsonString: string = this.encrDecrService.decrypt(EncrDecrService.AES_PASSWORD, atob(shortener.value));
        //     let permalinkData: PermalinkData = JSON.parse(jsonString);

        //     if (permalinkData.expirationDate && ((new Date()).getTime()) >= (new Date(permalinkData.expirationDate).getTime())) { //if the permalink has expired
        //       //do nothing (we will show a message later in permalinkProcessing of webCore)
        //     }
        //     else {
        //       if (permalinkData.securityGroup != null) {
        //         if (secGroups.find(s => s.id == permalinkData.securityGroup)) {
        //           secGroupId = permalinkData.securityGroup;//we change to the security group of the permalink
        //         }
        //         else {
        //           IDboxToastr.information(this.translator.translateText("PERMALINK.NOT_USER_IN_SECURITY_GROUP"), "");
        //         }
        //       }
        //       //else, we are on the first permalinks (which do not have security group)
        //     }
        //   }
        // }

        //we redirect automatically with the requested user security group
        await this.selectRequestedSecurityGroup(callbackSuccess, callbackError, secGroupId);
      },
      (error: Error) => {
        console.error('loadInitialConfiguration errorMsg = ' + error.message + ' errorStack=' + error.stack);
        callbackError(error);
      }
    );
  }

  async selectRequestedSecurityGroup(callbackSuccess: () => void, callbackError: (error) => void, alternativeSecGroupId?: number) {
    // Users are able to select a Security Group to switch from their default one
    const securityGroupSwitchId: number = alternativeSecGroupId != null ? alternativeSecGroupId : await this.getSecurityGroupSwitchStorage();
    // Return the Security Group switch or fallback to the user default one in case it is not defined
    if (securityGroupSwitchId != null && securityGroupSwitchId >= 0) {
      // We need to retrieve the full security group object (the loaded ones are summarized)
      await this.setSecurityGroupStorage(securityGroupSwitchId); // Target security group should be placed as current security group before requesting it due to backend requirements
      this.authService.getSecurityGroup(securityGroupSwitchId).pipe(take(1), takeUntil(this.ngUnsubscribe)).subscribe(
        async (securityGroup: SecurityGroup) => {
          if (securityGroup != null && securityGroup.id != null) {
            await this.onSecurityGroupSelected(callbackSuccess, callbackError, securityGroup);
          } else {
            await this.onSecurityGroupSwitchLoadFailed(callbackSuccess, callbackError);
          }
        },
        async error => {
          await this.onSecurityGroupSwitchLoadFailed(callbackSuccess, callbackError);
        }
      );
    } else {
      await this.onSecurityGroupSelected(callbackSuccess, callbackError, this.authService.userIDbox.defaultSecurityGroup);
    }
  }

  async onSecurityGroupSelected(callbackSuccess: () => void, callbackError: (error) => void, sec: SecurityGroup) {
    //Marcamos el security Group seleccionado y consultamos los permisos correspondiente, para luego redireccionar.
    await this.changeSelectedSecurityGroup(sec);
    let requests = [];

    requests.push(this.authService.loadPermissions());
    requests.push(this.preferences.getUserPreferences(this.authService.userIDbox.id, sec.id));
    requests.push(this.authService.loadAccessGroups());

    forkJoin(requests).pipe(take(1), takeUntil(this.ngUnsubscribe)).subscribe(response => {
      this.cacheService.clearAllCaches();//the security groups units, measures and categories, do not have to coincide
      this.preferences.userPreferences = response[1] as UserPreferences;
      this.preferences.loadPreferences(this.authService.userIDbox, sec);
      let acGroups: AccessGroup[] = response[2] as AccessGroup[];
      this.authService.availableAccessGroups = acGroups;
      callbackSuccess();
    }, error => {
      callbackError(error);
    });
  }

  private async onSecurityGroupSwitchLoadFailed(callbackSuccess: () => void, callbackError: (error) => void) {
    await this.removeSecurityGroupSwitchStorage();
    await this.onSecurityGroupSelected(callbackSuccess, callbackError, this.authService.userIDbox.defaultSecurityGroup);
    this.preferences.loadPreferences(this.authService.userIDbox, this.authService.userIDbox.defaultSecurityGroup);
  }
}
