import { Injectable } from '@angular/core';
import { PlatformEnum, PlatformHelperService } from '../utils/platform-helper.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigEnvironment, environments } from 'idbox-web-resources';
import { Application } from '../models/ApplicationsModel';

export class LoginDiscoveryConfiguration {
  issuer: string;
  redirectUri: string;
  clientId: string;
  responseType: string;
  scope: string;
}

@Injectable({
  providedIn: 'root'
})
export class MobileConfigurationAPIService {

  readonly loginDiscoveryEndpoint: string = "/logindiscovery";
  readonly mobileConfigurationEndpoint: string = "/configuration";
  readonly mobileConfigurationAPIDefaultPath: string = "/mobile-api";

  public static configEnvironment: ConfigEnvironment = new ConfigEnvironment();

  constructor(private platformHelperService: PlatformHelperService) {
  }

  /**
   * This method is used for obtaining the login discovery configuration
   */
  getLoginDiscoveryConfiguration(baseUrl: string): Observable<LoginDiscoveryConfiguration> {
    const platform: PlatformEnum = this.platformHelperService.getPlatformValue();
    const apiUrl: string = this.getApiUrl(baseUrl) + this.loginDiscoveryEndpoint;
    //TODO: Cambiar por llamada a la API. Por ahora mockeado
    let loginDiscoveryConfiguration: LoginDiscoveryConfiguration = new LoginDiscoveryConfiguration();
    loginDiscoveryConfiguration.issuer = baseUrl + "/iam/realms/idboxrt/";
    loginDiscoveryConfiguration.clientId = "ionic";
    loginDiscoveryConfiguration.responseType = "code";
    loginDiscoveryConfiguration.scope = "openid profile email offline_access";

    switch (platform) {
      case PlatformEnum.android:
      case PlatformEnum.ios:
        loginDiscoveryConfiguration.redirectUri = "idboxrt://login";
        break;
      case PlatformEnum.web:
        loginDiscoveryConfiguration.redirectUri = window.location.origin;
        //DESCOMENTAR LÍNEA DE ABAJO SI SE PUBLICA EN /mobile/ (hasta que esté la API disponible que nos dará este valor dinámicamente)
        //loginDiscoveryConfiguration.redirectUri+= "/mobile/";//De momento está puesto el /mobile/, pero este valor se tiene que obtener de la API, si tiramos en local, este /mobile/ no aplica
        break;
    }
    console.log("LoginDiscoveryConfiguration - redirectUri =", loginDiscoveryConfiguration.redirectUri);
    return of(loginDiscoveryConfiguration);
  }

  private getApiUrl(url: string): string {
    let apiUrl = url;
    try {
      const urlObj = new URL(url);
      if (urlObj.pathname === "/") { //if the URL has no path, we add the default path
        apiUrl += this.mobileConfigurationAPIDefaultPath;
      }
    } catch (e) {
      console.error("Invalid URL provided:", e);
      throw Error("Invalid URL provided"); //TODO: ESTE MENSAJE TIENE QUE SER TRADUCIDO
    }
    return apiUrl;
  }



  //This method is used for obtaining the config-environment
  getMobileConfiguration(baseUrl: string): Observable<ConfigEnvironment> {
    let configEnvironment = new ConfigEnvironment();
    const apiUrl: string = this.getApiUrl(baseUrl) + this.mobileConfigurationEndpoint;
    //TODO: Cambiar por llamada a la API. Por ahora mockeado

    environments.development.shortcuts = [
      {
        enabled: true,
        title: 'Luca',
        logo: 'icon-id-luca',
        url: '/luca-web/'
      },
      {
        enabled: true,
        title: 'Zabbix',
        logo: 'ti-dashboard',
        url: '/zabbix/',
      },
      {
        enabled: true,
        title: 'Eólica',
        logo: 'icon-id-aero',
        url: 'https://demo-old.idboxrt.com/CurvaPotenciaOld/home',
      },
      {
        enabled: true,
        title: 'Event Manager',
        logo: 'ti-announcement',
        url: 'https://event-manager-ovsys.idboxrt.com/',
      },
      {
        enabled: true,
        title: 'Volytica VDX',
        logo: 'ti-bolt',
        url: 'https://www.asset-cockpit.com/',
      },
      {
        enabled: true,
        title: 'Solar',
        logo: 'ti-shine',
        url: 'https://demo.idboxrt.com/solar',
      },
      {
        enabled: true,
        title: 'Fieldeas',
        logo: 'icon-id-worker',
        url: 'https://manager-pre.fieldeas.com/tracktrace/',
      }
    ];
    Object.assign(configEnvironment, environments.development);

    return of(configEnvironment).pipe(map(configEnvironment => {
      Object.assign(MobileConfigurationAPIService.configEnvironment, configEnvironment);//store it in the service, before returning it
      //we just assign, since otherwise we lose the reference, and the providers will not work, since this value is dynamic depending on the environment
      if (MobileConfigurationAPIService.configEnvironment != null &&
        MobileConfigurationAPIService.configEnvironment.shortcuts != null &&
        MobileConfigurationAPIService.configEnvironment.shortcuts.length > 0) {
        let id: number = 1;
        MobileConfigurationAPIService.configEnvironment.shortcuts.forEach(shortcut => {
          //This id is invented on the mobile app, just to be able to navigate from one app to another
          (shortcut as Application).id = id;//even if on the web config is not typed, we type it on the mobile app as "Application"
          id++;;
        });
      }
      return configEnvironment;
    }));
  }
}