import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {


  @Input() url : string;
  @Output() loginClick = new EventEmitter<string>();
  
  constructor() { }

  ngOnInit() {}

  onClickLogin() {
    this.loginClick.emit(this.url);
  }

}
