import { NgModule } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';

import { OAuthModule } from 'angular-oauth2-oidc';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { PagesModule } from './pages/pages.module';
import { CommonComponentsModule } from './components/components.module';
import { ConfigEnvironment, LocalDatePipe, LocalNumberPipe } from 'idbox-web-resources';
import { TranslateModule } from '@ngx-translate/core';
import { LoginComponent } from './login/login-component/login.component';
import { AuthenticationModule } from 'idbox-web-core-security-lib';
import { HttpMobileTokenInterceptor } from './core/http-mobile-token-interceptor';
import { MobileConfigurationAPIService } from './core/services/mobile-configuration-api.service';
import { BaseModule } from './base.module';

@NgModule({
  declarations: [AppComponent, MainMenuComponent, LoginComponent],
  imports: [
    BaseModule.forRoot(),
    BrowserAnimationsModule,
    IonicModule.forRoot({
      //TODO: Comprobar si en cada plataforma hay que distinguirlo o lo pilla el automáticamente y hacer el código en consecuencia o borrar esto (hasta que no se pueda probar en iOS no se verá)
      //rippleEffect: false,
      //mode: 'ios',
      //menuType: 'overlay'
    }),
    AppRoutingModule,
    HttpClientModule,
    OAuthModule.forRoot(),
    PagesModule,
    CommonComponentsModule,
    TranslateModule.forRoot(),
    AuthenticationModule.forRoot(HttpMobileTokenInterceptor),
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    LocalDatePipe, LocalNumberPipe,//available and same instance in all the app
  ]
})
export class AppModule { }
