import { Pipe, PipeTransform } from '@angular/core';
import { DataTypeSearchableListModal } from '../models/SearchableListModal.model';

@Pipe({
  name: 'searchBarFilter',
  pure: true
})
export class SearchBarFilterPipe implements PipeTransform {

  transform(items: DataTypeSearchableListModal[], value: string): DataTypeSearchableListModal[] {
    if (!items || !value) {
      return items;
    }
    return items.filter(e => e.name.toLowerCase().includes(value.toLocaleLowerCase()));
  }

}
